import { Guid } from 'guid-typescript';
import { DownloadableFile } from 'src/app/api/models/document-blob';

export interface FormState {
  SessionState: SessionState;
  SaisieAdresse: SaisieAdresse;
}

//#region Enums
export enum Selectionne {
  Undefined,
  Selection = 'Selection',
  Manuel = 'Manuel',
  Esh = 'Esh'
}

export enum Civilite {
  Madame = 'Madame',
  Monsieur = 'Monsieur',
  Autre = 'Autre'
}

export enum TypeBailleur {
  Social = 'Social',
  Prive = 'Privé'
}

export enum Raison {
  ChangementAssureur = 'ChangementAssureur',
  Emmenagement = 'Emmenagement'
}

export enum IdProduit {
  HC,
  HP,
  HS
}

export enum Formule {
  F1 = 'F1', //Confort
  F2 = 'F2', //Confort +
  LM = 'LM'
}

export enum TypeHabitation {
  Undefined,
  Appartement = 'Appartement',
  Maison = 'Maison',
  Autre = 'Autre'
}

export enum QualiteOccupant {
  Undefined,
  Proprietaire = 'Proprietaire',
  Locataire = 'Locataire'
}

export enum NatureResidence {
  Undefined,
  Principale = 'Principale',
  Secondaire = 'Secondaire'
}

export enum Etage {
  Undefined,
  RezDeChaussee = 'RezDeChaussee',
  Intermediaire = 'Intermediaire',
  DernierEtage = 'DernierEtage'
}

export enum SurfaceDependance {
  Aucune = 'Aucune',
  MoinsDe50m2 = 'MoinsDe50m2',
  De50a100m2 = 'De50a100m2',
  PlusDe100m2 = 'PlusDe100m2',
  PlusDe250m2 = 'PlusDe250m2'
}

export enum EcheancePrelevement {
  Mensuel = 'Mensuel',
  Trimestriel = 'Trimestriel',
  Annuel = 'Annuel'
}

export enum MontantValeurObjets2000 {
  Montant5000 = '5000',
  Montant10000 = '10000',
  Montant20000 = '20000'
}

export enum TypePromo {
  Pourcentage = 'Pourcentage',
  MoisOfferts = 'MoisOfferts'
}
//#endregion

//#region Saisie Adresse
export interface SaisieAdresse {
  Selection: SelectionGroup;
  Manuelle: EshGroup;
  ESH: EshGroup;
  Selectionne: Selectionne;
}

export interface EshGroup {
  Adresse: Esh;
  isValid: boolean;
}

export interface SelectionGroup {
  selection: Selection;
  isValid: boolean;
}

export interface Esh {
  NomBailleur?: string;
  CodePostal?: string;
  Ville?: string;
  Numero?: string;
  Rue?: string;
  NumeroEtRue?: string;
  Pays?: string;
}

export interface Selection {
  Adresse: string;
  AdresseEsh: Esh;
}
//#endregion

//#region Saisie Habitation
export interface SaisieHabitation {
  TypeHabitation?: TypeHabitation;
  QualiteOccupant?: QualiteOccupant;
  NatureResidence?: NatureResidence;
  TarifPreferentiel?: boolean;
  CompositionFoyer?: number;
  RFRInferieurPlafond?: boolean;
  ConfirmationRFRInferieurPlafond?: boolean;
  NombrePieces?: number;
  NombrePiecesSuperieures?: number;
  Etage?: Etage;
  AvecVeranda?: boolean;
  AntecedentSinistresInondables?: boolean;
  SurfaceDependance?: SurfaceDependance;
  InstallationDD?: boolean;
  PiscineSpaJacuzzi?: boolean;
  ObjetsValeurSuperieur2000?: boolean;
  PlafondMobilier?: Formule;
}
//#endregion

//#region Saisie Beneficiaire
export interface SaisieBeneficiaire {
  Civilite: Civilite;
  Nom: string;
  Prenom: string;
  Email: string;
  Telephone: string;
}
//#endregion

//#region Saisie Souscripteur
export interface SaisieInfosSouscripteur {
  Id: Guid;
  Civilite: Civilite;
  Nom: string;
  Prenom: string;
  Email: string;
  DateNaissance: string;
  LieuNaissance: string;
  PaysNaissance: string;
  Indicatif: string;
  Telephone: string;
  EstClient: boolean;
  Consentement: Consentement;
}

export interface Consentement {
  IdLibelle: number;
  VoiesConsentement: VoiesConsentement[];
}

export interface VoiesConsentement {
  Id: number;
  Libelle: string;
  IsCheck?: boolean;
}
//#endregion

//#region SaisiePersonnalisation
export interface SaisiePersonnalisation {
  MontantValeurObjets2000?: MontantValeurObjets2000;
  ProtectionJuridique: boolean;
  ProtectionVelos: boolean;
  ProtectionNomades: boolean;
  AssuranceScolaire?: AssuranceScolaire[];
}

export interface AssuranceScolaire {
  Numero: number;
  Nom: string;
  Prenom: string;
  DateNaissance: string;
}
//#endregion

//#region Saisie Choix Services
export interface SaisieChoixServices {
  EnvAttB: boolean;
  EnvoiAttestationBailleur?: EnvoiAttestationBailleur;
  ResAncAss: boolean;
  ResiliationAncienneAssurance?: ResiliationAncienneAssurance;
  OppositionMarketing: boolean;
  NombreEnfants?: number;
}

export interface EnvoiAttestationBailleur {
  TypeBailleur?: TypeBailleur;
  BailleurPrive?: BailleurPrive;
  BailleurSocial?: BailleurSocial;
  isValid: boolean;
}

export interface BailleurPrive {
  NomBailleur: string | null;
  Email: string;
}

export interface BailleurSocial {
  NomBailleur: string;
  Email: string | null;
  LogementOccupe: boolean | null;
  Reference1: string;
  Reference2: string;
}

export interface ResiliationAncienneAssurance {
  Raison?: Raison;
  DemenagementPlusUnAn?: boolean;
  NomAssureur: string;
  AddressAssureur: string;
  CodePostalAssureur: string;
  VilleAssureur: string;
  EmailAssureur: string;
  NumeroContrat: string;
  MoisDemarrageContrat: string;
  DateEnvoiLR: string | null;
  isValid: boolean;
}
//#endregion

//#region Payment
export interface SaisiePaiement {
  EcheancePrelevement?: EcheancePrelevement;
  DateDemarrage?: string;
  JourPrelevement?: string;
  IBAN: string;
  Telephone: string;
  PrefixeTel: string;
  TitulaireCompte?: boolean;
  ConsentementTelephonique: boolean;
  PaymentTelephone?: string;
  PaymentPrefixTel?: string;
}
//#endregion

//#region Saisie Signature
export interface SaisieSignature {
  AcceptationCP: boolean;
  AcceptationFIC: boolean;
  AcceptationMandatSEPA: boolean;
  SignatureToken?: string;
  CodeSms?: string;
}
//#endregion

export interface SaisieFormule {
  CodePromo?: string | undefined;
  FormuleSelectionnee?: Formule;
  ValeurMensuelle: number;
  ValeurAnnuelle: number;
}

export interface SaisieProduit {
  IdProduit: IdProduit;
  FormuleRecommandee: Formule;
}

export interface SessionState {
  Etape: string;
}

export interface Tarification {
  Promotion?: Promotion;
  Formules?: FormuleApi[];
  TarifId?: string;
}

export interface FormuleApi {
  NomFormule?: string;
  PrimeMensuelle?: number;
  PrimeAnnuelle?: number;
  PrimesOptions?: PrimesOptions;
}

export interface PrimesOptions {
  ObjetsValeur?: ObjetsValeur;
  OptionProtectionJuridique?: string;
  ProtectionJuridique?: number;
  OptionProtectionVelos?: string;
  ProtectionVelos?: number;
  OptionProtectionNomades?: string;
  ProtectionNomades?: number;
  OptionAssuranceScolaire?: string;
  AssuranceScolaire?: PrimesAssuranceScolaire;
}

export interface PrimesAssuranceScolaire {
  Enfant1?: number;
  Enfant2?: number;
  Enfant3?: number;
  Enfant4Plus?: number;
}

export interface Promotion {
  CampagneEnCours?: boolean;
  CodePromoApplique?: boolean;
  CodePromoValide?: boolean;
  TypePromo?: TypePromo;
  DetailsPromo?: string;
  CodePromo?: string;
}

export interface ObjetsValeur {
  Valeur5000?: number;
  Valeur10000?: number;
  Valeur15000?: number;
}

export interface Devis {
  AgreementId?: Guid;
  NumeroDevis: string;
  DevisId?: Guid;
  DevisStatus?: number;
  TarifId?: string;
  PrimeMensuelle?: number;
  PrimeAnnuelle?: number;
  PrimesOptions?: PrimesOptions;
  DocumentDevis?: DownloadableFile;
  DocumentFIC?: DownloadableFile;
  DocumentInfo?: DownloadableFile;
  DocumentCG?: DownloadableFile;
  GDAPositif: boolean;
  PPEPositif: boolean;
  ClientAdvisorFullName: string;
}

export interface Contract {
  EstSansEffet: boolean;
  EstSigne: boolean;
  NumeroContrat: string;
  RefContrat?: string;
  RefDocumentCP: string;
  RefDocumentFIC: string;
  RefDocumentMandatSEPA: string;
  RefDocumentCG: string;
  RefDocumentAttestation: string;
  RefDocumentsSI: string[];
  ClientAdvisorFullName: string;
}

export interface Paiement {
  IBANValide: boolean;
}

export interface Signature {
  CodeSMSValide: boolean;
}
