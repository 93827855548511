import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, map, take } from 'rxjs';
import { PriceService } from 'src/app/api/services/price.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { Formule, SaisieFormule, SaisieHabitation } from 'src/app/core/models/form-state.model';
import { Question } from 'src/app/core/models/question.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromAddress from 'src/app/core/state/address';
import { AddressState } from 'src/app/core/state/address';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromHousing from 'src/app/core/state/housing';
import * as fromInfo from 'src/app/core/state/info';
import * as fromOffer from 'src/app/core/state/offer-customization';
import * as fromPath from 'src/app/core/state/path';
import * as fromTarification from 'src/app/core/state/tarification';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { OFFER_CUSTOMIZARION_PATH, ROOT_PATH } from 'src/app/shared/constants/route.constants';
import {
  getMetropoleBaseName,
  getMetropoleClassTwo
} from 'src/app/shared/helpers/metropole-helper.service';
import { InsuranceBenefitVM } from 'src/app/shared/models/components/insurancebenefitVm';
import { FormuleRate, rateVM } from 'src/app/shared/models/components/rateVm';
import { PackageCode } from 'src/app/shared/models/enum/packageCode.enum';
import { TypeOfferEnum } from 'src/app/shared/models/enum/typeoffer.enum';
import {
  BaremeValeurAssureeElement,
  ReferenceDataService
} from 'src/app/shared/services/reference-data-service';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateComponent extends BaseComponent implements OnInit {
  override step: Step = {
    step: this.brand === 'HM' ? this.resource.header.stepQuote : this.resource.header.stepOffer,
    stepNumber: 2,
    subStep: this.resource.header.subStepRate,
    subStepNumber: 3
  };

  override question: Question = {
    libelle: '',
    popupInfo: undefined
  };

  F1!: FormuleRate;
  F2!: FormuleRate;
  rateVM!: rateVM;

  productClass!: string;
  benefitM!: string;
  typeOfferEnum = TypeOfferEnum;
  offerCount!: number;
  prom: boolean = false;
  promPercentage: number = 0;
  promString!: string;
  promNoDescription: boolean = false;

  iB_CP: boolean = false;
  iB_C: boolean = false;
  insuranceBenefitCPVms: {
    topPart: InsuranceBenefitVM[];
    bottomPart: InsuranceBenefitVM[];
  } = {
    topPart: [],
    bottomPart: []
  };
  insuranceBenefitCVms: {
    topPart: InsuranceBenefitVM[];
    bottomPart: InsuranceBenefitVM[];
  } = {
    topPart: [],
    bottomPart: []
  };

  iB_P: boolean = false;
  insuranceBenefitMVms!: InsuranceBenefitVM[];

  iB_S: boolean = false;
  insuranceBenefitSVms!: InsuranceBenefitVM[];

  typeOffer: string = TypeOfferEnum.C;

  promoForm!: FormGroup;
  path!: PathType;
  context!: string;
  adresse!: AddressState;
  formuleRecommandee!: Formule;
  saisieFormule!: SaisieFormule;
  saisieHabitation!: SaisieHabitation;

  nbRooms!: number;
  bareme!: BaremeValeurAssureeElement;

  FormuleRecommandeeEnum = Formule;
  PathType = PathType;

  isPromoLoading: boolean = false;
  invalidPromoCodeError: boolean = false;
  isAdvisor: boolean = false;

  displayError: boolean = false;

  imgSrc: string = this.resource.rate.buttonPlusPath;
  PromotionCodeError?: string;
  get code() {
    return this.promoForm?.get('code')!;
  }

  get brand() {
    return environment.brand;
  }

  constructor(
    private router: Router,
    store: Store<State>,
    tracker: MatomoTracker,
    resources: AppResource,
    private currencyPipe: CurrencyPipe,
    private priceService: PriceService,
    private refDataService: ReferenceDataService
  ) {
    super(store, resources, tracker);

    this.rateVM = new rateVM();
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(false);
    await this.loadStateData();
    this.productClass = getMetropoleClassTwo(this.path) ?? '';
    const baseName = getMetropoleBaseName(this.path);
    this.benefitM = String.format(this.resource.rate.benefitM, baseName);

    this.getLibelle(this.offerCount);
  }

  private async loadStateData() {
    var offers = await lastValueFrom(
      this.store.select(fromTarification.selectTarificationFormules).pipe(take(1))
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    const context = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    this.context = context!;

    this.adresse = await lastValueFrom(this.store.select(fromAddress.selectAddress).pipe(take(1)));

    const stateOffer = await lastValueFrom(
      this.store.select(fromOffer.selectOfferCustomization).pipe(take(1))
    );

    this.formuleRecommandee = stateOffer.FormuleRecommandee!;
    this.saisieFormule = stateOffer.SaisieFormule;

    this.saisieHabitation = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(
        take(1),
        map(s => s.SaisieHabitation)
      )
    );

    this.store.select(fromContext.selectContext).subscribe(res => {
      if (res.contextData != null)
        if (res.contextData?.modeConnexion !== 'COB') {
          this.isAdvisor = true;
        }
    });

    this.nbRooms =
      (this.saisieHabitation?.NombrePieces ?? 0) +
      (this.saisieHabitation?.NombrePiecesSuperieures ?? 0);

    var baremeValeurAssuree = this.refDataService.baremeValeurAssuree;
    this.bareme = baremeValeurAssuree.find(x => x.NombrePieces == this.nbRooms)!;

    this.initPromoForm();
    let tmpF1 = offers?.find(x => x.NomFormule === Formule.F1);
    let tmpF2 = offers?.find(x => x.NomFormule === Formule.F2);

    switch (this.path) {
      case PathType.HOUSING:
        tmpF1 = offers?.find(x => x.NomFormule === Formule.F1);
        tmpF2 = offers?.find(x => x.NomFormule === Formule.F2);
        this.offerCount = this.resource.rate.twoOffer;
        this.F1 = new FormuleRate(
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeAnnuelle!,
          this.currencyPipe.transform(tmpF1?.PrimeMensuelle!),
          environment.assConfort
        );
        this.F2 = new FormuleRate(
          tmpF2?.PrimeMensuelle!,
          tmpF2?.PrimeMensuelle!,
          tmpF2?.PrimeAnnuelle!,
          this.currencyPipe.transform(tmpF2?.PrimeMensuelle!),
          environment.assConfort
        );
        this.getBenefit(TypeOfferEnum.C);
        this.getBenefit(TypeOfferEnum.CP);
        this.iB_CP = true;
        this.iB_C = true;
        break;
      case PathType.HOUSING_PARIS:
      case PathType.HOUSING_TOURCOING:
      case PathType.HOUSING_LILLE:
        tmpF1 = offers?.find(x => x.NomFormule === Formule.LM);
        this.offerCount = this.resource.rate.oneOffer;
        const productName = getMetropoleBaseName(this.path);
        if (productName) {
          this.F1 = new FormuleRate(
            tmpF1?.PrimeMensuelle!,
            tmpF1?.PrimeMensuelle!,
            tmpF1?.PrimeAnnuelle!,
            this.currencyPipe.transform(tmpF1?.PrimeMensuelle!),
            String.format(environment.assMetropole, productName)
          );
        }
        this.getBenefit(TypeOfferEnum.P);
        this.iB_P = true;
        break;
      case PathType.HOUSING_SOLIDARITY:
        tmpF1 = offers?.find(x => x.NomFormule === Formule.LM);
        this.offerCount = this.resource.rate.oneOffer;
        this.F1 = new FormuleRate(
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeAnnuelle!,
          this.currencyPipe.transform(tmpF1?.PrimeMensuelle!),
          environment.assSolidaire
        );
        this.getBenefit(TypeOfferEnum.S);
        this.iB_S = true;
        break;
      case PathType.UNDEFINED:
        this.offerCount = this.resource.rate.twoOffer;
        this.F1 = new FormuleRate(
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeMensuelle!,
          tmpF1?.PrimeAnnuelle!,
          this.currencyPipe.transform(tmpF1?.PrimeMensuelle!),
          environment.assConfort
        );

        this.F2 = new FormuleRate(
          tmpF2?.PrimeMensuelle!,
          tmpF2?.PrimeMensuelle!,
          tmpF2?.PrimeAnnuelle!,
          this.currencyPipe.transform(tmpF2?.PrimeMensuelle!),
          environment.assConfort
        );
        this.getBenefit(TypeOfferEnum.C);
        this.getBenefit(TypeOfferEnum.CP);
        this.iB_CP = true;
        this.iB_C = true;
    }
    this.rateVM.F1 = this.F1;
    this.rateVM.F2 = this.F2;

    if (this.saisieFormule.CodePromo) {
      var promoDescription = await lastValueFrom(
        this.store.select(fromTarification.selectTarificationPromotion).pipe(
          take(1),
          map(p => p?.DetailsPromo)
        )
      );
      this.prom = true;
      this.promString = promoDescription ?? '';
      this.promNoDescription = !promoDescription;
    }
  }

  initPromoForm() {
    this.promoForm = new FormGroup({
      code: new FormControl<string | null>(null, {
        validators: [Validators.required, this.checkPromoApplied()]
      })
    });
  }

  checkPromoApplied(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.invalidPromoCodeError ? { promo: {} } : null;
    };
  }

  getLibelle(count: number) {
    if (count === 1) {
      this.store.dispatch(
        fromInfo.updateQuestion({ payload: { libelle: this.resource.question.offer1 } })
      );
    } else {
      this.store.dispatch(
        fromInfo.updateQuestion({
          payload: {
            libelle: String.format(
              this.resource.question.offer2,
              `${this.formuleRecommandee === 'F2' ? '+' : ''}`
            )
          }
        })
      );
    }
  }

  getBenefit(offer: string) {
    switch (offer) {
      case TypeOfferEnum.CP:
        this.insuranceBenefitCPVms.topPart = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB1,
            this.resource.insurancebenefit.textB_IB1,
            this.resource.insurancebenefit.text2_IB1,
            '',
            [
              this.resource.insurancebenefit.desc1_IB1,
              this.resource.insurancebenefit.desc2CP_IB1,
              this.resource.insurancebenefit.desc3CP_IB1,
              this.resource.insurancebenefit.desc4CP_IB1,
              this.resource.insurancebenefit.desc5CP_IB1
            ]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB2,
            this.resource.insurancebenefit.textB_IB2,
            this.resource.insurancebenefit.text2_IB2,
            '',
            [this.resource.insurancebenefit.desc1_IB2, this.resource.insurancebenefit.desc2_IB2]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1CP_IB3,
            this.resource.insurancebenefit.text1BCP_IB3,
            this.resource.insurancebenefit.text2CP_IB3,
            this.resource.insurancebenefit.text2BCP_IB3,
            [this.resource.insurancebenefit.desc1_IB3, this.resource.insurancebenefit.desc2CP_IB3]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB4,
            this.resource.insurancebenefit.textB_IB4,
            this.resource.insurancebenefit.text2_IB4,
            '',
            [
              this.resource.insurancebenefit.desc1_IB4,
              this.resource.insurancebenefit.desc2_IB4,
              this.resource.insurancebenefit.desc3_IB4,
              this.resource.insurancebenefit.desc4_IB4,
              this.resource.insurancebenefit.desc5_IB4
            ]
          )
        ];
        this.insuranceBenefitCPVms.bottomPart = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB7,
            String.format(
              this.resource.insurancebenefit.textB_IB7,
              this.currencyPipe.transform(this.bareme.F2, 'EUR', 'symbol', '5.0-0')
            ),
            '',
            '',
            [
              String.format(
                this.resource.insurancebenefit.descCP1_IB7,
                this.currencyPipe.transform(this.bareme.F2, 'EUR', 'symbol', '5.0-0')
              ),
              this.resource.insurancebenefit.descCP2_IB7
            ]
          ),
          new InsuranceBenefitVM(
            '',
            this.resource.insurancebenefit.textB_IB5,
            this.resource.insurancebenefit.text2_IB5,
            '',
            [this.resource.insurancebenefit.desc1_IB5, this.resource.insurancebenefit.desc2_IB5]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB6,
            this.resource.insurancebenefit.textB_IB6,
            '',
            '',
            [this.resource.insurancebenefit.desc1_IB6]
          )
        ];
        break;
      case TypeOfferEnum.C:
        this.insuranceBenefitCVms.topPart = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB1,
            this.resource.insurancebenefit.textB_IB1,
            this.resource.insurancebenefit.text2_IB1,
            '',
            [
              this.resource.insurancebenefit.desc1_IB1,
              this.resource.insurancebenefit.desc2CP_IB1,
              this.resource.insurancebenefit.desc3CP_IB1,
              this.resource.insurancebenefit.desc4CP_IB1,
              this.resource.insurancebenefit.desc5CP_IB1
            ]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB2,
            this.resource.insurancebenefit.textB_IB2,
            this.resource.insurancebenefit.text2_IB2,
            '',
            [this.resource.insurancebenefit.desc1_IB2, this.resource.insurancebenefit.desc2_IB2]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1CP_IB3,
            this.resource.insurancebenefit.text1BCP_IB3,
            this.resource.insurancebenefit.text2CP_IB3,
            this.resource.insurancebenefit.text2BCP_IB3,
            [this.resource.insurancebenefit.desc1_IB3, this.resource.insurancebenefit.desc2CP_IB3]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB4,
            this.resource.insurancebenefit.textB_IB4,
            this.resource.insurancebenefit.text2_IB4,
            '',
            [
              this.resource.insurancebenefit.desc1_IB4,
              this.resource.insurancebenefit.desc2_IB4,
              this.resource.insurancebenefit.desc3_IB4,
              this.resource.insurancebenefit.desc4_IB4,
              this.resource.insurancebenefit.desc5_IB4
            ]
          )
        ];
        this.insuranceBenefitCVms.bottomPart = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB7,
            String.format(
              this.resource.insurancebenefit.textB_IB7,
              this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
            ),
            '',
            '',
            [
              String.format(
                this.resource.insurancebenefit.descC1_IB7,
                this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
              ),
              this.resource.insurancebenefit.descC2_IB7
            ]
          )
        ];
        break;
      case TypeOfferEnum.P:
        this.insuranceBenefitMVms = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB1,
            this.resource.insurancebenefit.textB_IB1,
            this.resource.insurancebenefit.text2_IB1,
            '',
            [this.resource.insurancebenefit.desc1_IB1, this.resource.insurancebenefit.desc2_IB1]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB2,
            this.resource.insurancebenefit.textB_IB2,
            this.resource.insurancebenefit.text2_IB2,
            '',
            [this.resource.insurancebenefit.desc1_IB2, this.resource.insurancebenefit.desc2_IB2]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB3,
            this.resource.insurancebenefit.textB_IB3,
            '',
            '',
            [this.resource.insurancebenefit.desc1_IB3, this.resource.insurancebenefit.desc2_IB3]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB4,
            this.resource.insurancebenefit.textB_IB4,
            this.resource.insurancebenefit.text2_IB4,
            '',
            [
              this.resource.insurancebenefit.desc1_IB4,
              this.resource.insurancebenefit.desc2_IB4,
              this.resource.insurancebenefit.desc3_IB4,
              this.resource.insurancebenefit.desc4_IB4,
              this.resource.insurancebenefit.desc5_IB4
            ]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB7,
            String.format(
              this.resource.insurancebenefit.textB_IB7,
              this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
            ),
            '',
            '',
            [
              String.format(
                this.resource.insurancebenefit.descC1_IB7,
                this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
              )
            ]
          )
        ];
        break;
      case TypeOfferEnum.S:
        this.insuranceBenefitSVms = [
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB1,
            this.resource.insurancebenefit.textB_IB1,
            this.resource.insurancebenefit.text2_IB1,
            '',
            [this.resource.insurancebenefit.desc1_IB1, this.resource.insurancebenefit.desc2_IB1]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB2,
            this.resource.insurancebenefit.textB_IB2,
            this.resource.insurancebenefit.text2_IB2,
            '',
            [this.resource.insurancebenefit.desc1_IB2, this.resource.insurancebenefit.desc2_IB2]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB3,
            this.resource.insurancebenefit.textB_IB3,
            '',
            '',
            [this.resource.insurancebenefit.desc1_IB3, this.resource.insurancebenefit.desc2_IB3]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB4,
            this.resource.insurancebenefit.textB_IB4,
            this.resource.insurancebenefit.text2_IB4,
            '',
            [
              this.resource.insurancebenefit.desc1_IB4,
              this.resource.insurancebenefit.desc2_IB4,
              this.resource.insurancebenefit.desc3_IB4,
              this.resource.insurancebenefit.desc4_IB4,
              this.resource.insurancebenefit.desc5_IB4
            ]
          ),
          new InsuranceBenefitVM(
            this.resource.insurancebenefit.text1_IB7,
            String.format(
              this.resource.insurancebenefit.textB_IB7,
              this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
            ),
            '',
            '',
            [
              String.format(
                this.resource.insurancebenefit.descC1_IB7,
                this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
              )
            ]
          )
        ];
        break;
      default:
        break;
    }
  }

  offerSelected(offer: TypeOfferEnum) {
    var ValeurMensuelle = 0;
    var ValeurAnnuelle = 0;
    var FormuleSelectionnee = Formule.F1;
    switch (offer) {
      case TypeOfferEnum.CP:
        ValeurMensuelle = parseFloat(this.F2.rateF.toFixed(2));
        ValeurAnnuelle = this.F2.rateF_Annuel;
        FormuleSelectionnee = Formule.F2;
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {
              FormuleSelectionnee,
              ValeurMensuelle,
              ValeurAnnuelle
            } as Partial<SaisieFormule>
          })
        );
        break;
      case TypeOfferEnum.C:
        ValeurMensuelle = parseFloat(this.F1.rateF.toFixed(2));
        ValeurAnnuelle = this.F1.rateF_Annuel;
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {
              FormuleSelectionnee,
              ValeurMensuelle,
              ValeurAnnuelle
            } as Partial<SaisieFormule>
          })
        );
        break;
      case TypeOfferEnum.P:
        ValeurMensuelle = parseFloat(this.F1.rateF.toFixed(2));
        ValeurAnnuelle = this.F1.rateF_Annuel;
        var FormuleSelectionnee = Formule.LM;
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {
              FormuleSelectionnee,
              ValeurMensuelle,
              ValeurAnnuelle
            } as Partial<SaisieFormule>
          })
        );
        break;
      case TypeOfferEnum.S:
        var FormuleSelectionnee = Formule.LM;
        ValeurMensuelle = parseFloat(this.F1.rateF.toFixed(2));
        ValeurAnnuelle = this.F1.rateF_Annuel;
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {
              FormuleSelectionnee,
              ValeurMensuelle,
              ValeurAnnuelle
            } as Partial<SaisieFormule>
          })
        );
        break;
      default:
        break;
    }
    this.updateStepper();
    this.router.navigate([`${ROOT_PATH}${OFFER_CUSTOMIZARION_PATH}`]);
  }

  async updateRate() {
    this.isPromoLoading = true;

    const codePromoSaisi = this.code.value;

    // Recreate tarif with the inserted code promo
    var tarifBody = ApiRequestBodyUtils.GenerateTarifCreateRequestBody(
      this.context,
      this.path,
      this.brand,
      this.adresse.adresseType,
      this.adresse.addresseSelection,
      this.adresse.addresseManuelle,
      this.adresse.adresseEsh,
      this.saisieHabitation,
      {
        ...this.saisieFormule,
        CodePromo: codePromoSaisi, // Code promo input
        FormuleSelectionnee: undefined // Recalculate all formules
      },
      this.formuleRecommandee
    );

    // Remove code promo validation error
    this.setCodePromoValidationError(false);

    this.priceService.GetProductPrices(tarifBody).subscribe({
      next: res => {
        this.promString = '';
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {} as Partial<SaisieFormule>
          })
        );
        this.prom = false;
        // Show promotion
        if (res.promotion != null) {
          this.promString = res.promotion?.promotionLabel ?? '';
          this.prom = true;
        }
        // Store returned informations about formules in the state
        const tarifInfos = ApiResponseBodyUtils.ExtractTarifResponseBody(res);
        this.store.dispatch(
          fromTarification.patchTarification({
            payload: tarifInfos
          })
        );

        // Save promo data in state
        this.store.dispatch(
          fromOffer.patchSaisieFormule({
            payload: {
              CodePromo: codePromoSaisi
            } as Partial<SaisieFormule>
          })
        );

        // Update VM with new 'formule'
        const packagePrice = res.packages.find(
          pack =>
            pack.code === PackageCode.CONF ||
            pack.code === PackageCode.PARISIENNE ||
            pack.code === PackageCode.TOURQUENNOISE ||
            pack.code === PackageCode.LILLOISE ||
            pack.code === PackageCode.SOLIDAIRE
        );
        const packagePriceConfPlus = res.packages.find(pack => pack.code === PackageCode.CONF_PLUS);
        this.updatePromoRateVM(
          packagePrice?.monthlyPremiumAmountIncludingTax ?? 0,
          packagePriceConfPlus?.monthlyPremiumAmountIncludingTax,
          packagePrice?.annualPremiumAmountIncludingTax ?? 0,
          packagePriceConfPlus?.annualPremiumAmountIncludingTax ?? 0
        );

        // Remove input value
        this.code.reset();
      },
      error: result => {
        this.isPromoLoading = false;
        if (result.status === 404 && result['error']) {
          this.code.reset();
          this.setCodePromoValidationError(true);
          this.PromotionCodeError = result.error.detail;
        } else {
          this.displayError = true;
        }
      },
      complete: () => {
        this.isPromoLoading = false;
      }
    });
  }

  private updatePromoRateVM(
    pricePromoF1: number | undefined,
    pricePromoF2: number | undefined,
    pricePromoF1_Annuel: number,
    pricePromoF2_Annuel: number
  ) {
    if (pricePromoF1 !== undefined) {
      this.F1.rateF = pricePromoF1;
      this.F1.rateF_Annuel = pricePromoF1_Annuel;
      this.F1.rateF_Show = this.currencyPipe.transform(this.F1.rateF);
    }
    if (pricePromoF2 !== undefined) {
      this.F2.rateF = pricePromoF2;
      this.F2.rateF_Annuel = pricePromoF2_Annuel;
      this.F2.rateF_Show = this.currencyPipe.transform(this.F2.rateF);
    }
  }

  private setCodePromoValidationError(isValid: boolean): void {
    this.invalidPromoCodeError = isValid;
    this.promoForm.controls['code'].updateValueAndValidity();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.rate
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.offercustomization
    );

    if (subtitleN) subtitleN.isActive = true;

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  onMouseOver() {
    this.imgSrc = this.resource.rate.buttonPlusColorPath;
  }

  onMouseOut() {
    this.imgSrc = this.resource.rate.buttonPlusPath;
  }
}
