export const environment = {
  production: true,
  development: false,
  test: false,
  brand: 'Vyv',
  apiV2RootUrl: 'https://dev.assurance.vyv-conseil.fr/api/',
  baseUrl: 'https://dev.assurance-habitation.vyv-conseil.fr/',
  matomotoKey: 'CHANGEME',
  appInsightsKey: 'f409faaf-8841-41c5-aa3f-a35a34bc220d',
  assConfort: 'https://saboiardbackofficeb3l5w.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Confort_Tableau%20des%20garanties.pdf',
  assMetropole: 'https://saboiardbackofficeb3l5w.blob.core.windows.net/public/Plaquette_Assurance_Habitation_{0}_Tableau%20des%20garanties.pdf',
  assSolidaire:
    'https://saboiardbackofficeb3l5w.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Solidaire_Tableau%20des%20garanties.pdf',
  matomoSiteId: 6,
  matomoHostUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/',
  matomoScriptUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/js/container_682wvypB.js',
  modeleCourrierResiliation: 'https://saboiardbackofficeb3l5w.blob.core.windows.net/public/ModeleCourrier.docx',
  msalConfig: {
    auth: {
      clientId: 'd4f9c412-00c9-4121-8962-ab92e07faf53',
      authority: 'https://login.microsoftonline.com/6f12f368-4cb1-4b07-9574-dd2cdf95d9c1',
      redirectUri: 'https://dev.assurance-habitation.vyv-conseil.fr/'
    }
  },
  apiConfig: {
    scopes: ['api://boiard-dev-back-office/Read.All', 'api://boiard-dev-back-office/Write.All'],
    uri: 'https://dev.assurance.vyv-conseil.fr/api/'
  }
};
